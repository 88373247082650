<template>
  <div
    ref="popup"
    class="md:block"
    :class="{
      hidden: !open,
    }"
  >
    <PopUpFrame
      v-if="isMobile"
      show-arrow
      top-position="top-md"
      @close="$emit('close')"
    >
      <template #headline>
        {{ t('andvancedList.filter.popup.headline') }}
      </template>
      <template #content>
        <div class="!px-md max-h-[65vh] overflow-auto">
          <div class="text-sm cursor-pointer text-primary-base">
            <div class="md:ml-md mr-xs" @click="$emit('resetFilters')">
              <FaIcon icon-class="fas fa-arrow-rotate-left" />
              {{ t('andvancedList.filterUndo') }}
            </div>
          </div>
          <slot />
        </div>
      </template>
    </PopUpFrame>

    <div v-else class="!px-md md:!px-0">
      <slot />
    </div>
  </div>
</template>
<script setup lang="ts">
import PopUpFrame from '~/components/dialog/components/dialogFrame/popup/popupFrame.vue';
import FaIcon from '@/components/fa-icon.vue';
import { SSR_safe_mq_breakpointIsMobile } from '~/injectionSymbols';

const { t } = useTrans();
const isMobile = inject(SSR_safe_mq_breakpointIsMobile);

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'resetFilters'): void;
}>();

const popup = ref();
onClickOutside(popup, () => emit('close'));

defineProps({
  open: {
    type: Boolean,
    default: false,
  },
});
</script>
